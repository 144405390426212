<template>
  <div class="ui-transfer">
    <div class="ui-transfer__nft">
      <div class="ui-transfer__nft-image">
        <ui-illustration
          :image="image"
          :type="type"
        />
      </div>

      <div class="ui-transfer__nft-infos">
        <span
          class="ui-transfer__status"
          :class="{
            '-pending': isPending,
            '-errored': isErrored
          }"
        >{{ $t(`conn3ct-wallet.transfer_status_${ value.status }`) }}</span>

        <span
          v-if="!isDone && !isErrored && escrowDate"
          class="ui-transfer__status-date"
        >{{ $t('conn3ct-wallet.transfer_on_escrow_on', { date: escrowDate }) }}</span>

        <span
          v-if="revertedDate"
          class="ui-transfer__status-date"
        >{{ $t('conn3ct-wallet.transfer_reverted_on', { date: revertedDate }) }}</span>

        <span
          v-if="isPending && revertTransferOn"
          class="ui-transfer__status-date"
        >{{ $t('conn3ct-wallet.transfer_expire_on', { date: revertTransferOn }) }}</span>


        <h5 class="ui-transfer__nft-title">{{ nftTitle }}</h5>

        <span
          v-if="destination"
          class="ui-transfer__nft-destination"
        >{{ $t('conn3ct-wallet.transfered_to', { destination }) }}</span>

        <span
          v-if="creationDate"
          class="ui-transfer__status-date"
        >{{ $t('conn3ct-wallet.transfer_created_on', { date: creationDate }) }}</span>
      </div>
    </div>

    <div class="ui-transfer__actions">
      <actions-button
        v-if="isRevertable"
        @click="cancelTransfer"
        :size="$pepper.Size.XS"
      >{{ $t('conn3ct-wallet.cancel_transfer') }}</actions-button>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import NftTransfer from '../../datalayer/wallet/models/wallet/nft-transfer'

export default {
  name: 'UiTransfer',

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => {
    return {
      type: 'image',
    }
  },

  computed: {
    nft() {
      return this.value?.nft ?? null
    },

    nftTitle() {
      return this.translate(this.nft?.name)
    },

    nftDescription() {
      return this.translate(this.nft?.description)
    },

    destination() {
      return this.value.toEmail ?? this.value.toWalletAddress
    },

    image() {
      return this.nft?.imageUrl
    },

    isPending() {
      return !![
        NftTransfer.statuses.QUEUED,
        NftTransfer.statuses.PENDING_WALLET_CREATION,
        NftTransfer.statuses.PENDING_CLAIM,
        NftTransfer.statuses.CLAIMED_PENDING_TRANSFER
      ].find(s => s === this.value.status)
    },

    isErrored() {
      return !![
        NftTransfer.statuses.NOT_ENOUGH_FUNDS,
        NftTransfer.statuses.ERROR,
        NftTransfer.statuses.EXPIRED,
        NftTransfer.statuses.REVERTED
      ].find(s => s === this.value.status)
    },

    isRevertable() {
      return !![
        NftTransfer.statuses.PENDING_WALLET_CREATION,
        NftTransfer.statuses.PENDING_CLAIM
      ].find(s => s === this.value.status)
    },

    isDone() {
      return this.value?.status === NftTransfer.statuses.CONFIRMED
    },

    revertedDate() {
      return this.value.revertDate?.toLocaleString(DateTime.DATETIME_SHORT) ?? null
    },

    revertTransferOn() {
      return this.value.revertTransferOn?.toLocaleString(DateTime.DATETIME_SHORT) ?? null
    },

    creationDate() {
      return this.value.created?.toLocaleString(DateTime.DATETIME_SHORT) ?? null
    },

    escrowDate() {
      return this.value.escrowDate?.toLocaleString(DateTime.DATETIME_SHORT) ?? null
    }
  },

  methods: {
    async init() {
      try {
        this.type = await this.nft?.imageType
      } catch(e) {
        $console.error(e)
      }
    },

    async cancelTransfer() {
      try {
        await this.$wallet.cancelTransferNft({ transferId: this.value.id })
        this.$notification({
          title: this.$t('conn3ct-wallet.transfer_reverted'),
          type: 'success'
        })
        this.$emit('refresh')
      } catch(e) {
        $console.error(e)
        this.$notification({
          title: this.$t('conn3ct-wallet.transfer_cannot_be_reverted'),
          type: 'error'
        })
      }
    }
  },

  created() {
    this.init()
  }
}
</script>

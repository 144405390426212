<script>
import UiTransfer from '@/components/ui/transfer'
import { DateTime } from 'luxon'
import NftTransfer from '@/datalayer/wallet/models/wallet/nft-transfer'

export default {
  name: 'ViewTransfers',

  components: {
    UiTransfer
  },


  data() {
    return {
      loading: false,
      revertableStatuses: [20, 30]
    }
  },

  computed: {
    isPageLoading() {
      return this.isLoading
    },

    hasTransfers() {
      return this.$wallet.transfers.length !== 0
    },

    orderedTransfers() {
      return this.$wallet.transfers?.sort((t1, t2) => {
        if(this.revertableStatuses.includes(t1.status) && this.revertableStatuses.includes(t2.status)) return 0
        if(this.revertableStatuses.includes(t1.status) && !this.revertableStatuses.includes(t2.status)) return -1
        return 1
      })
    }
  },

  methods: {
    async cancelTransfer(transfer) {
      try {
        await this.$wallet.cancelTransferNft({ transferId: transfer.id })

        this.$notification({
          title: this.$t('conn3ct-wallet.transfer_reverted'),
          type: 'success'
        })

        this.init()
      } catch(e) {
        $console.error(e)
        this.$notification({
          title: this.$t('conn3ct-wallet.transfer_cannot_be_reverted'),
          type: 'error'
        })
      }
    },

    getDate(transfer, field) {
      return transfer[field]?.toLocaleString(DateTime.DATETIME_SHORT) ?? null
    },

    getDestination(transfer) {
      return transfer?.toEmail ?? transfer?.toWalletAddress
    },

    async init() {
      try {
        this.loading = true
        await this.$wallet.getTransferNft({})
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    isErrored(transfer) {
      return !![
        NftTransfer.statuses.NOT_ENOUGH_FUNDS,
        NftTransfer.statuses.ERROR,
        NftTransfer.statuses.EXPIRED,
        NftTransfer.statuses.REVERTED
      ].find(s => s === transfer.status)
    },

    isPending(transfer) {
      return !![
        NftTransfer.statuses.QUEUED,
        NftTransfer.statuses.PENDING_WALLET_CREATION,
        NftTransfer.statuses.PENDING_CLAIM,
        NftTransfer.statuses.CLAIMED_PENDING_TRANSFER
      ].find(s => s === transfer.status)
    },


    isRevertable(transfer) {
      return !![
        NftTransfer.statuses.PENDING_WALLET_CREATION,
        NftTransfer.statuses.PENDING_CLAIM
      ].find(s => s === transfer.status)
    },
  },

  mounted() {
    this.init()
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_wallet-wallet', params: { id: 'sayl' }}"
    class="module-wallet transfers"
    :laoding="loading"
    :subtitle="$t('conn3ct-wallet.transfers_history_subtitle')"
    :title="$t('conn3ct-wallet.transfers_history_title')"
  >
    <ui-panel
      v-if="!hasTransfers"
      class="transfers__empty"
    >
      <template v-slot:header>
        <h3 class="title">{{ $t('conn3ct-wallet.wallet_no_transfers_title') }}</h3>
      </template>

      <div class="body">
        <p>{{ $t('conn3ct-wallet.wallet_no_transfers_description') }}</p>

        <actions-button
          @click="$router.push({ name: 'sayl-connect_wallet-wallet', params: { id: 'sayl' }})"
          :appearance="$pepper.Appearance.PRIMARY"
          class="transfers__empty-cta"
        >{{ $t('conn3ct-wallet.back_to_wallet') }}</actions-button>
      </div>
    </ui-panel>

    <div
      v-else
      class="transfers__list"
    >
      <ui-panel
        v-for="transfer in orderedTransfers"
        :key="transfer.id"
      >
        <template v-slot:header>
          <div
            class="title transfers__transfer-header"
          >
            <div class="transfers__transfer-title">
              <ui-illustration
                class="transfers__transfer-image"
                fit="contain"
                :src="transfer.nft.imageUrl"
                :type="transfer.nft.imageType"
              />

              <div>
                <p class="transfers__transfer-nft-name">{{ transfer.nft.name }}</p>
                <p class="transfers__transfer-destination">{{ $t('conn3ct-wallet.transfered_to', { destination: getDestination(transfer) }) }}</p>
              </div>
            </div>

            <div class="transfers__transfer-status">
              <div
                class="transfers__status"
                :class="{
                  '-pending': isPending(transfer),
                  '-errored': isErrored(transfer)
                }"
              >{{ $t(`conn3ct-wallet.transfer_status_${ transfer.status }`) }}</div>

              <actions-button
                v-if="isRevertable(transfer)"
                @click="cancelTransfer(transfer)"
                class="transfers__transfer-button"
                :size="$pepper.Size.S"
              >{{ $t('conn3ct-wallet.cancel_transfer') }}</actions-button>
            </div>
          </div>
        </template>

        <div class="body">
          <p
            v-if="getDate(transfer, 'created')"
            class="transfers__info-row"
          >
            <span class="transfers__info-label">{{ $t('conn3ct-wallet.transfer_created_on') }}</span>
            <span class="transfers__info-value">{{ getDate(transfer, 'created') }}</span>
          </p>

          <!-- <p
            v-if="getDate(transfer, 'escrowDate')"
            class="transfers__info-row"
          >
            <span class="transfers__info-label">{{ $t('conn3ct-wallet.transfer_on_escrow_on') }}</span>
            <span class="transfers__info-value">{{ getDate(transfer, 'escrowDate') }}</span>
          </p> -->

          <p
            v-if="getDate(transfer, 'revertTransferOn')"
            class="transfers__info-row"
          >
            <span class="transfers__info-label">{{ $t('conn3ct-wallet.transfer_expire_on') }}</span>
            <span class="transfers__info-value">{{ getDate(transfer, 'revertTransferOn') }}</span>
          </p>
        </div>
      </ui-panel>
    </div>
  </layout-page>
</template>
